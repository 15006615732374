import { createBrowserRouter, Navigate } from 'react-router-dom';
import React from 'react';
import { Suspense } from 'react';
import LogoIntro from '../components/loaders/logointro';
import ActionHandler from 'components/authFirebaseCode';

const Login = React.lazy(() => import('components/auth/Login'));
const Register = React.lazy(() => import('components/auth/Register'));
const Reset = React.lazy(() => import('components/auth/Reset'));
const Verification = React.lazy(() => import('components/auth/Verification'));
const Layout = React.lazy(() => import('components/layout'));
const Dashboard = React.lazy(() => import('components/dashboard'));
const Comments = React.lazy(() => import('components/comments'));
const Profile = React.lazy(() => import('components/profile'));
const NotFound = React.lazy(() => import('utils/404Error'));
const SearchPage = React.lazy(() => import('components/navbar/Search'));
const NetworkPage = React.lazy(() => import('components/network/networkPage'));
const Findr = React.lazy(() => import('components/findr/findr'));
const MessageMu = React.lazy(() => import('components/messageMu/messageMu'));
const PayMu = React.lazy(() => import('components/payMu/indexTest.js')); // For Normal version remove indexTest
const ForumPage = React.lazy(() => import('components/forum'));
const Forums = React.lazy(() => import('components/forum/forums'));
const ForumPosts = React.lazy(() => import('components/forum/forumPosts'));
const Billing = React.lazy(() => import('components/billing/billing'));
const AdminPanel = React.lazy(() => import('components/admin'));
const Settings = React.lazy(() => import('components/settings'));
const InStudio = React.lazy(() => import('components/instudio/indexTest.js')); // For Normal version remove indexTest
const Groups = React.lazy(() => import('components/messageMu/group'));
const Requests = React.lazy(() => import('components/messageMu/requests'));
const ComingSoon = React.lazy(() => import('components/comingSoon/ComingSoon'));
const LandingPage = React.lazy(() => import('components/landing/LandingPage'));
const SharedPost = React.lazy(() => import('components/sharedPost/SharedPost'));
const Reverification = React.lazy(() => import('components/auth/Reverification'));

export const ROOT = '/';
export const LOGIN = '/login';
export const REGISTER = '/register';
export const VERIFICATION = '/verification';
export const RESET = '/resetPassword';
export const VERIFIED = '/authAction';
export const REVERIFY = '/verifyAuth';

export const PROTECTED = '/protected';
export const DASHBOARD = '/protected/dashboard';
export const USER = '/protected/profile/:username';
export const COMMENTS = '/protected/comments/:id';
export const SEARCH = '/protected/search';
export const NETWORK = '/protected/network';
export const FINDR = '/protected/findr';
export const MESSAGEMU = '/protected/messageMu/messages';
export const MESSAGEMUSER = '/protected/messageMu/messages/:id';
export const GROUPS = '/protected/messageMu/groups';
export const REQUESTS = '/protected/messageMu/requests';
export const PAYMU = '/protected/paymu';
export const PAYMUSESSION = '/protected/paymu/:username/:id';
export const FORUM = '/protected/forum';
export const FORUMS = '/protected/forums/:title/:id';
export const FORUMPOSTS = '/protected/forum/forumPosts/:forumId/:postId';
export const BILLING = '/protected/billing';
export const ADMIN = '/protected/admin';
export const SETTINGS = '/protected/settings';
export const INSTUDIO = '/protected/instudio';
export const LANDING = '/home';
export const SHARED_POST = '/shared/:postId';

export const COMING_SOON = '/coming-soon';

export const checkAndRedirect = () => {
  if (
    window.location.hostname === 'musicom-d43cd.web.app' ||
    window.location.hostname === 'musicom-d43cd.firebaseapp.com'
  ) {
    window.location.href = 'https://musicomapp.com';
  }
  //return DASHBOARD;
};

const rootChildren = [
  {
    path: '',
    element: <Navigate to={checkAndRedirect()} />,
  },
  {
    path: LANDING,
    element: (
      <Suspense
        fallback={
          <div>
            <LogoIntro />
          </div>
        }
      >
        <LandingPage />
      </Suspense>
    ),
  },
  {
    path: COMING_SOON,
    element: (
      <Suspense
        fallback={
          <div>
            <LogoIntro />
          </div>
        }
      >
        <ComingSoon />
      </Suspense>
    ),
  },
  {
    path: LOGIN,
    element: (
      <Suspense
        fallback={
          <div>
            <LogoIntro />
          </div>
        }
      >
        <Login />
      </Suspense>
    ),
  },
  {
    path: REGISTER,
    element: (
      <Suspense
        fallback={
          <div>
            <LogoIntro />
          </div>
        }
      >
        <Register />
      </Suspense>
    ),
  },
  {
    path: VERIFICATION,
    element: (
      <Suspense
        fallback={
          <div>
            <LogoIntro />
          </div>
        }
      >
        <Verification />
      </Suspense>
    ),
  },
  {
    path: VERIFIED,
    element: (
      <Suspense
        fallback={
          <div>
            <LogoIntro />
          </div>
        }
      >
        <ActionHandler />
      </Suspense>
    ),
  },
  {
    path: RESET,
    element: (
      <Suspense
        fallback={
          <div>
            <LogoIntro />
          </div>
        }
      >
        <Reset />
      </Suspense>
    ),
  },
  {
    path: SHARED_POST,
    element: (
      <Suspense
        fallback={
          <div>
            <LogoIntro />
          </div>
        }
      >
        <SharedPost />
      </Suspense>
    ),
  },
  {
    path: VERIFIED,
    element: (
      <Suspense
        fallback={
          <div>
            <LogoIntro />
          </div>
        }
      >
        <ActionHandler />
      </Suspense>
    ),
  },
  {
    path: REVERIFY,
    element: (
      <Suspense
        fallback={
          <div>
            <LogoIntro />
          </div>
        }
      >
        <Reverification />
      </Suspense>
    ),
  },
  {
    path: '*',
    element: (
      <Suspense
        fallback={
          <div>
            <LogoIntro />
          </div>
        }
      >
        <NotFound />
      </Suspense>
    ),
  },
];

const protectedChildren = [
  {
    path: DASHBOARD,
    element: (
      <Suspense
        fallback={
          <div>
            <LogoIntro />
          </div>
        }
      >
        <Dashboard />
      </Suspense>
    ),
  },
  {
    path: USER,
    element: (
      <Suspense
        fallback={
          <div>
            <LogoIntro />
          </div>
        }
      >
        <Profile />{' '}
      </Suspense>
    ),
  },
  {
    path: COMMENTS,
    element: (
      <Suspense
        fallback={
          <div>
            <LogoIntro />
          </div>
        }
      >
        <Comments />{' '}
      </Suspense>
    ),
  },
  {
    path: SEARCH,
    element: (
      <Suspense
        fallback={
          <div>
            <LogoIntro />
          </div>
        }
      >
        <SearchPage />{' '}
      </Suspense>
    ),
  },
  {
    path: NETWORK,
    element: (
      <Suspense
        fallback={
          <div>
            <LogoIntro />
          </div>
        }
      >
        <NetworkPage />{' '}
      </Suspense>
    ),
  },
  {
    path: FINDR,
    element: (
      <Suspense
        fallback={
          <div>
            <LogoIntro />
          </div>
        }
      >
        <Findr />{' '}
      </Suspense>
    ),
  },
  {
    path: MESSAGEMU,
    element: (
      <Suspense
        fallback={
          <div>
            <LogoIntro />
          </div>
        }
      >
        <MessageMu />{' '}
      </Suspense>
    ),
  },
  {
    path: MESSAGEMUSER,
    element: (
      <Suspense
        fallback={
          <div>
            <LogoIntro />
          </div>
        }
      >
        <MessageMu />{' '}
      </Suspense>
    ),
  },
  {
    path: GROUPS,
    element: (
      <Suspense
        fallback={
          <div>
            <LogoIntro />
          </div>
        }
      >
        <MessageMu />{' '}
      </Suspense>
    ),
  },
  {
    path: REQUESTS,
    element: (
      <Suspense
        fallback={
          <div>
            <LogoIntro />
          </div>
        }
      >
        <MessageMu />{' '}
      </Suspense>
    ),
  },
  {
    path: PAYMU,
    element: (
      <Suspense
        fallback={
          <div>
            <LogoIntro />
          </div>
        }
      >
        <PayMu />{' '}
      </Suspense>
    ),
  },
  {
    path: PAYMUSESSION,
    element: (
      <Suspense
        fallback={
          <div>
            <LogoIntro />
          </div>
        }
      >
        <PayMu />{' '}
      </Suspense>
    ),
  },
  {
    path: FORUM,
    element: (
      <Suspense
        fallback={
          <div>
            <LogoIntro />
          </div>
        }
      >
        <ForumPage />{' '}
      </Suspense>
    ),
  },
  {
    path: FORUMS,
    element: (
      <Suspense
        fallback={
          <div>
            <LogoIntro />
          </div>
        }
      >
        <Forums />{' '}
      </Suspense>
    ),
  },
  {
    path: FORUMPOSTS,
    element: (
      <Suspense
        fallback={
          <div>
            <LogoIntro />
          </div>
        }
      >
        <ForumPosts />{' '}
      </Suspense>
    ),
  },
  // {
  //   path: BILLING,
  //   element: (
  //     <Suspense fallback={<div>Loading...</div>}>
  //       <Billing />{' '}
  //     </Suspense>
  //   ),
  // }, hided it for now
  {
    path: ADMIN,
    element: (
      <Suspense
        fallback={
          <div>
            <LogoIntro />
          </div>
        }
      >
        <AdminPanel />{' '}
      </Suspense>
    ),
  },
  {
    path: SETTINGS,
    element: (
      <Suspense
        fallback={
          <div>
            <LogoIntro />
          </div>
        }
      >
        <Settings />{' '}
      </Suspense>
    ),
  },
  {
    path: INSTUDIO,
    element: (
      <Suspense
        fallback={
          <div>
            <LogoIntro />
          </div>
        }
      >
        <InStudio />{' '}
      </Suspense>
    ),
  },
  {
    path: '*',
    element: (
      <Suspense
        fallback={
          <div>
            <LogoIntro />
          </div>
        }
      >
        <NotFound />{' '}
      </Suspense>
    ),
  },
];

export const router = createBrowserRouter([
  {
    path: ROOT,
    element: <Layout />,
    children: rootChildren,
  },
  {
    path: PROTECTED,
    element: <Layout />,
    children: protectedChildren,
  },
]);
