import React, { useEffect, useState, useMemo } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { applyActionCode, confirmPasswordReset, getAuth } from 'firebase/auth';
import { auth } from 'lib/firebase';
import {
  Box,
  VStack,
  Heading,
  Text,
  Button,
  Spinner,
  Flex,
  useColorModeValue,
  Image,
  Icon,
  useBreakpointValue,
  Input,
  FormControl,
  FormErrorMessage,
} from '@chakra-ui/react';
import { DASHBOARD, LOGIN } from 'lib/routes';
import logo from 'Musicom Resources/Blue Logo Design/No Background/0.75x/Blue-White Text Logo copy@0.75x.png';
import logoM from 'Musicom Resources/Blue Logo Design/No Background/0.75x/Blue-White Icon Logo copy@0.75x.png';
import { FiCheckCircle, FiAlertTriangle } from 'react-icons/fi';
import { motion } from 'framer-motion';

const MotionBox = motion(Box);

const MusicNote = React.memo(() => {
  const startX = Math.random() * 100;
  const startY = Math.random() * 100;
  const endX = startX + (Math.random() - 0.5) * 20;
  const endY = startY + (Math.random() - 0.5) * 20;
  const duration = 30 + Math.random() * 20;
  const delay = Math.random() * 0.1;
  const size = 10 + Math.random() * 20;
  const opacity = 0.1 + Math.random() * 0.3;
  const rotationSpeed = Math.random() < 0.5 ? 360 : -360;
  const movementPattern = Math.random() < 0.5 ? 'linear' : 'easeInOut';

  return (
    <motion.div
      style={{
        position: 'absolute',
        top: `${startY}%`,
        left: `${startX}%`,
        opacity: opacity,
      }}
      animate={{
        x: [0, endX - startX, 0],
        y: [0, endY - startY, 0],
        opacity: [opacity, opacity * 1.5, opacity],
        rotate: [0, rotationSpeed, 0],
      }}
      transition={{
        duration,
        delay,
        repeat: Infinity,
        repeatType: 'loop',
        ease: movementPattern,
      }}
    >
      <Image
        src={logoM}
        alt="Musicom Logo"
        width={`${size}%`}
        height={`${size}%`}
        filter={`drop-shadow(0 0 2px rgba(255, 255, 255, 0.5))`}
      />
    </motion.div>
  );
});

const BackgroundAnimation = React.memo(() => {
  return (
    <Box
      position="absolute"
      top={0}
      left={0}
      right={0}
      bottom={0}
      pointerEvents="none"
      zIndex={0}
    >
      {[...Array(50)].map((_, index) => (
        <MusicNote key={index} />
      ))}
    </Box>
  );
});

const ActionHandler = () => {
  const [status, setStatus] = useState('processing');
  const [error, setError] = useState('');
  const [newPassword, setNewPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const location = useLocation();
  const navigate = useNavigate();
  const bgColor = useColorModeValue('gray.50', 'gray.900');
  const cardBgColor = useColorModeValue('white', 'gray.800');

  const logoSize = useBreakpointValue({
    base: '60%',
    sm: '50%',
    md: '40%',
  });
  const textSize = useBreakpointValue({ base: 'sm', sm: 'md', md: 'lg' });
  const cardWidth = useBreakpointValue({ base: '90%', sm: '80%', md: '400px' });

  const [passwordChecks, setPasswordChecks] = useState({
    length: false,
    upper: false,
    lower: false,
    number: false,
    special: false,
  });

  const validatePassword = (password) => {
    setPasswordChecks({
      length: password.length >= 8,
      upper: /[A-Z]/.test(password),
      lower: /[a-z]/.test(password),
      number: /[0-9]/.test(password),
      special: /[!@#$%^&*(),.?":{}|<>]/.test(password),
    });
  };

  const handlePasswordChange = (e) => {
    const newPassword = e.target.value;
    setNewPassword(newPassword);
    validatePassword(newPassword);
  };

  useEffect(() => {
    const handleAction = async () => {
      const params = new URLSearchParams(window.location.search);
      const mode = params.get('mode');
      const oobCode = params.get('oobCode');
      const auth = getAuth();

      if (!oobCode) {
        setStatus('error');
        setError('Invalid action code');
        return;
      }

      try {
        if (mode === 'verifyEmail') {
          await applyActionCode(auth, oobCode);
          setStatus('success');
        } else if (mode === 'resetPassword') {
          // Don't apply the action code here, just set the status
          setStatus('resetPassword');
        } else {
          throw new Error('Invalid action mode');
        }
      } catch (error) {
        console.error('Action error:', error);
        setStatus('error');
        setError(error.message);
      }
    };

    handleAction();
  }, []);

  const handleResetPassword = async () => {
    if (newPassword !== confirmPassword) {
      setError("Passwords don't match");
      return;
    }

    const params = new URLSearchParams(location.search);
    const oobCode = params.get('oobCode');

    try {
      await confirmPasswordReset(auth, oobCode, newPassword);
      setStatus('success');
    } catch (error) {
      console.error('Password reset error:', error);
      setStatus('error');
      setError(error.message);
    }
  };

  const handleContinue = () => {
    if (auth.currentUser) {
      navigate(DASHBOARD);
    } else {
      navigate(LOGIN);
    }
  };

  const isPasswordValid =
    newPassword &&
    confirmPassword &&
    newPassword === confirmPassword &&
    Object.values(passwordChecks).every((check) => check);

  return (
    <Box
      minHeight="100vh"
      width="100%"
      bg={bgColor}
      position="fixed"
      top={0}
      left={0}
      right={0}
      bottom={0}
      overflow="hidden"
    >
      <BackgroundAnimation />

      <Flex
        direction="column"
        align="center"
        justify="center"
        minHeight="100vh"
        p={4}
        zIndex={1}
        position="relative"
      >
        <Image src={logo} alt="Musicom Logo" width={logoSize} mb={8} />
        <MotionBox
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.5 }}
          bg={cardBgColor}
          p={8}
          borderRadius="lg"
          boxShadow="xl"
          width={cardWidth}
        >
          <VStack spacing={6} align="center">
            {status === 'processing' && (
              <>
                <Spinner size="xl" color="blue.500" />
                <Heading size="lg">Processing...</Heading>
                <Text fontSize={textSize} textAlign="center">
                  Please wait while we process your request.
                </Text>
              </>
            )}

            {status === 'success' && (
              <>
                <Icon as={FiCheckCircle} w={16} h={16} color="green.500" />
                <Heading size="lg" color="green.500">
                  Action Completed!
                </Heading>
                <Text fontSize={textSize} textAlign="center">
                  Your request has been successfully processed.
                </Text>
                <Button
                  colorScheme="blue"
                  size="lg"
                  width="100%"
                  onClick={handleContinue}
                >
                  Continue to Musicom
                </Button>
              </>
            )}

            {status === 'resetPassword' && (
              <>
                <Heading size="lg">Reset Password</Heading>
                <FormControl isInvalid={!!error}>
                  <Input
                    type="password"
                    placeholder="Enter new password"
                    value={newPassword}
                    onChange={handlePasswordChange}
                    mb={3}
                  />
                  <Input
                    type="password"
                    placeholder="Confirm new password"
                    value={confirmPassword}
                    onChange={(e) => setConfirmPassword(e.target.value)}
                    mb={3}
                  />
                  <FormErrorMessage>{error}</FormErrorMessage>
                </FormControl>
                <VStack align="start" spacing={0} width="100%" mb={4}>
                  <Text fontSize="sm" color="gray.500">
                    Password must:
                  </Text>
                  <Text
                    fontSize="sm"
                    color={passwordChecks.length ? 'green.500' : 'red.500'}
                  >
                    • Be at least 8 characters long
                  </Text>
                  <Text
                    fontSize="sm"
                    color={passwordChecks.upper ? 'green.500' : 'red.500'}
                  >
                    • Contain at least one uppercase letter
                  </Text>
                  <Text
                    fontSize="sm"
                    color={passwordChecks.lower ? 'green.500' : 'red.500'}
                  >
                    • Contain at least one lowercase letter
                  </Text>
                  <Text
                    fontSize="sm"
                    color={passwordChecks.number ? 'green.500' : 'red.500'}
                  >
                    • Contain at least one number
                  </Text>
                  <Text
                    fontSize="sm"
                    color={passwordChecks.special ? 'green.500' : 'red.500'}
                  >
                    • Contain at least one special character
                  </Text>
                </VStack>
                <Button
                  colorScheme="blue"
                  size="lg"
                  width="100%"
                  onClick={handleResetPassword}
                  isDisabled={!isPasswordValid}
                >
                  Reset Password
                </Button>
              </>
            )}

            {status === 'error' && (
              <>
                <Icon as={FiAlertTriangle} w={16} h={16} color="red.500" />
                <Heading size="lg" color="red.500">
                  Action Failed
                </Heading>
                <Text fontSize={textSize} textAlign="center">
                  {error ||
                    'There was an error processing your request. Please try again or contact support.'}
                </Text>
                <Button
                  colorScheme="blue"
                  size="lg"
                  width="100%"
                  onClick={() => navigate(LOGIN)}
                >
                  Back to Login
                </Button>
              </>
            )}
          </VStack>
        </MotionBox>
      </Flex>
    </Box>
  );
};

export default ActionHandler;
