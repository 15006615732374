import {
  ChakraProvider,
  CSSReset,
  ColorModeScript,
  useColorMode,
} from '@chakra-ui/react';
import { RouterProvider } from 'react-router-dom';
import { router } from 'lib/routes';
import { useEffect } from 'react';
// import { ThirdwebProvider } from '@thirdweb-dev/react';
// import {
//   metamaskWallet,
//   coinbaseWallet,
//   walletConnect,
//   embeddedWallet,
//   localWallet,
//   trustWallet,
// } from '@thirdweb-dev/react';
import {
  ThirdwebProvider,
  // metamaskWallet,
  // coinbaseWallet,
  // walletConnect,
  // trustWallet,
} from 'thirdweb/react';
import { createThirdwebClient } from 'thirdweb';

function App() {
  const { colorMode, toggleColorMode } = useColorMode();

  useEffect(() => {
    localStorage.setItem('colorMode', colorMode); // Store the color mode preference in local storage
  }, [colorMode]);

  const client = createThirdwebClient({
    secretKey: process.env.REACT_APP_THIRDWEB_KEY,
  });

  // List of routes where we want to hide the navbar
  const hideNavbarRoutes = ['/login', '/register', '/reset'];

  return (
    <ThirdwebProvider>
      <ChakraProvider>
        <CSSReset />
        <ColorModeScript
          initialColorMode={localStorage.getItem('colorMode') || 'light'}
        />
        <RouterProvider
          router={router}
          colorMode={colorMode}
          toggleColorMode={toggleColorMode}
        />
      </ChakraProvider>
    </ThirdwebProvider>
  );
}

export default App;
