import React from 'react';
import { Box, Image } from '@chakra-ui/react';
import { keyframes } from '@emotion/react'; // Correct import from @emotion/react
import logoM from 'Musicom Resources/Blue Logo Design/No Background/0.75x/Blue-White Icon Logo copy@0.75x.png';

// Define keyframes for animation
const zoomIn = keyframes`
  0% {
    transform: scale(0);
    opacity: 0;
  }
  50% {
    transform: scale(1);
    opacity: 1;
  }
  100% {
    transform: scale(0);
    opacity: 0;
  }
`;

const LogoIntro = ({ isMobile }) => {
  return (
    <Box
      display="flex"
      justifyContent="center"
      alignItems="center"
      height="100%"
    >
      <Box
        animation={`${zoomIn} 2s ease-in-out infinite`}
        width={isMobile ? '150px' : '200px'}
        height={isMobile ? '150px' : '200px'}
        mt="0"
      >
        <Image src={logoM} alt="Logo" width="100%" height="100%" />
      </Box>
    </Box>
  );
};

export default LogoIntro;
