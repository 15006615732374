import { initializeApp } from 'firebase/app';
import { getFirestore } from 'firebase/firestore';
import { getAuth } from 'firebase/auth';
import { getStorage } from 'firebase/storage';
import { getAnalytics } from 'firebase/analytics';
import { getStripePayments } from '@stripe/firestore-stripe-payments';

const firebaseConfig = {
  apiKey: 'AIzaSyBjIZ4yPKKLPko-OqOUZBzs3u7D5-JT1Dw',
  authDomain: 'musicomapp.com',
  projectId: 'musicom-d43cd',
  storageBucket: 'musicom-d43cd.appspot.com',
  messagingSenderId: '339663582383',
  appId: '1:339663582383:web:b1dc5b3da8267c78e1505a',
  measurementId: 'G-0PQ0B3WMY2',
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const db = getFirestore(app);
const auth = getAuth(app);
const storage = getStorage(app);
const firestore = getFirestore(app);
const analytics = getAnalytics(app);
const payments = getStripePayments(app, {
  productsCollection: 'products',
  customersCollection: 'users',
});

auth.config.authDomain = 'musicomapp.com';
export { app, db, auth, storage, firestore, analytics, payments };

// import { initializeApp } from 'firebase/app';
// import { getFirestore } from 'firebase/firestore';
// import { getAuth } from 'firebase/auth';
// import { getStorage } from 'firebase/storage';
// import { getAnalytics } from 'firebase/analytics';
// import { getStripePayments } from '@stripe/firestore-stripe-payments';

// const firebaseConfig = {
//   apiKey: process.env.REACT_APP_FIREBASE_API,
//   authDomain: `${process.env.REACT_APP_FIREBASE_PROJECT_ID}.firebaseapp.com`,
//   projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
//   storageBucket: `${process.env.REACT_APP_FIREBASE_PROJECT_ID}.appspot.com`,
//   messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_ID,
//   appId: process.env.REACT_APP_FIREBASE_APP_ID,
//   measurementId: process.env.REACT_APP_FIREBASE_MEASUREMENT_ID,
//   databaseURL: `https://${process.env.REACT_APP_FIREBASE_PROJECT_ID}.firebaseio.com`,
// };

// // Initialize Firebase
// const app = initializeApp(firebaseConfig);

// // Get a reference to your named database
// const db = getFirestore(app, 'testers'); // Use the 'testers' database
// const auth = getAuth(app);
// const storage = getStorage(app);
// const firestore = getFirestore(app);
// const analytics = getAnalytics(app);
// const payments = getStripePayments(db, {
//   // Pass the Firestore instance here
//   productsCollection: 'products',
//   customersCollection: 'users',
// });

// export { app, db, auth, firestore, storage, analytics, payments };
